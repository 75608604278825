import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import OpleidingImage from "../components/opleidingen"

const Opleidingen = () => (
  <Layout>
    <SEO title="Opleidingen" />
    <div class="pt-40">
      <div class="container mt-4 px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
        <h2>Opleiding voor het individu</h2>
        <p class="flex w-full space-between">JCI helpt je een groeiende mindset te ontwikkelen, waardoor je nog sterker zal worden als individu!</p>
        <p class="flex w-full space-between">Door deel te nemen aan het leven van de lokale bevolking, door verantwoordelijkheden te nemen binnen een commissie of de raad van bestuur, kan iedereen zichzelf beter leren kennen, zijn vaardigheden en expertise verder ontwikkelen, zijn leiderschap versterken, betere beslissingen nemen of beter in groepsverband leren samenwerken. JCI Dendermonde organiseert daartoe regelmatig opleidingen.</p>
        <p class="flex w-full space-between">Voor onze opleidingen werken we samen met het&nbsp;<a class="text-blue-600" href="https://cvofocus.be/">CVO Focus</a>. <br />
        </p>
        <p class="mt-4 w-full">In Vlaanderen beschikt JCI over een eigen opleidingsinstituut, met name het JCI TIF (Training Institute Flanders). U kunt een overzicht van hun opleidingen <a class="text-blue-600" href="https://jci.vlaanderen/tif/aanbod/">hier</a> raadplegen.</p>
        <OpleidingImage></OpleidingImage>
      </div>
    </div>
  </Layout>
)

export default Opleidingen
